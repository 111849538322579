import { useTranslation } from 'react-i18next';
import { PrismicNextImage } from '@prismicio/next';
import clsx from 'clsx';
import Modal from '@/components/molecules/Modal';
import { CloseIcon } from '@/public/assets/svg';
import { CryptoSelectorModalProps } from './types';
const CryptoSelectorModal = ({
  isOpen,
  onClose,
  onSubmit,
  coins,
  selectedCoinSymbol
}: CryptoSelectorModalProps) => {
  const {
    t
  } = useTranslation();
  return <Modal {...{
    isOpen
  }} contentClassName="relative min-w-[20rem] max-w-[30rem] border border-gray-200" data-sentry-element="Modal" data-sentry-component="CryptoSelectorModal" data-sentry-source-file="index.tsx">
      <div className="flex flex-col items-center">
        <div className="flex w-full flex-row items-center justify-between border-b border-b-gray-200 p-4">
          <h2 className="text-center text-h7 font-bold">
            {t('preSellForm.finalAmountStep.selectAToken')}
          </h2>
          <CloseIcon className="h-6 w-6 cursor-pointer text-gray-600" onClick={onClose} data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
        </div>
        <div className="flex w-full flex-col gap-1 py-2">
          {coins.map(coin => <button type="button" key={coin.symbol} className={clsx('flex w-full flex-row items-center gap-2 px-3 py-2 focus:bg-gray-50', {
          'bg-gray-50': selectedCoinSymbol === coin.symbol
        })} onClick={() => onSubmit(coin?.symbol?.toString() || '')}>
              <PrismicNextImage field={coin.icon} width={32} height={32} />
              <p className="text-body-7 font-medium text-gray-600">{`${coin.name} (${coin.symbol})`}</p>
            </button>)}
        </div>
      </div>
    </Modal>;
};
export default CryptoSelectorModal;