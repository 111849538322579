'use client';

import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArrowSwapHorizontalIcon, DollarBillIcon } from '@/public/assets/svg';
import { CryptoCurrency } from '@/consts/api';
import { useLang } from '@/hooks/useLang';
import CryptoSelector from '@/app/[lang]/pre-sell/partials/PreSellForm/partials/Steps/FinalAmountStep/partials/CryptoSelector';
import { clampValue } from '../CalculatorDesktop/utils';
import { useETransferCryptoAmount } from './hooks';
import { CalculatorVerticalProps, FormCalculatorVerticalProps } from './types';
import { makeFinalAmountValidator } from './utils';
const CalculatorVertical = React.forwardRef<HTMLInputElement, CalculatorVerticalProps>(({
  className,
  name,
  amount,
  setAmount,
  minValue,
  maxValue,
  amountCurrency,
  fiatInputLabel,
  cryptoInputLabel,
  prices,
  disabled,
  onBlur,
  cryptoCurrency = CryptoCurrency.BTC,
  coins,
  onChangeCrypto
}, ref) => {
  const [selectedCrypto, setSelectedCrypto] = useState<string>(cryptoCurrency);
  const {
    amount: cryptoAmount
  } = useETransferCryptoAmount(amount || 0, selectedCrypto as CryptoCurrency, prices);
  const handleCryptoChange = (crypto: string) => {
    setSelectedCrypto(crypto);
    if (onChangeCrypto) {
      onChangeCrypto(crypto);
    }
  };
  return <div className={clsx('w-full bg-white', className)}>
        <div className="mb-5 flex h-[16.25rem] flex-col items-center">
          <div className={clsx('relative flex h-full w-full flex-col justify-between rounded-[.625rem] border p-4 md:p-5', `after:absolute after:bg-gray-100 after:content-['']`, 'after:bottom-[-1.125rem]', 'after:left-0 after:right-0 after:ml-auto after:mr-auto after:h-8 after:w-[3.5rem] md:after:h-9 md:after:w-[4.25rem]', 'after:rounded-t-full after:rounded-bl-none after:rounded-br-none after:border-b-white')}>
            <p className="mb-3 text-body-8 text-gray-500">{fiatInputLabel}</p>
            <div className="flex flex-row justify-between">
              <input ref={ref} name={name} className="w-28 text-body-5 font-bold focus:outline-none md:text-h6" type="number" value={amount || ''} onChange={event => setAmount(event.target.valueAsNumber)} onBlur={event => {
            setAmount(clampValue(event.target.valueAsNumber, minValue, maxValue));
            onBlur?.(event);
          }} disabled={disabled} />
              <div className={clsx('flex flex-row items-center gap-2 rounded-full border border-gray-200 px-3 py-2 md:w-[7.5rem]', {
            'w-[6.75rem] md:w-[7.5rem]': coins && coins.length > 0
          })}>
                <div className="flex items-center justify-center rounded-full bg-gray-100 p-1.5">
                  <DollarBillIcon className="h-3 w-3 md:h-5 md:w-5" />
                </div>
                <span className="text-body-7 font-semibold text-gray-600 md:text-body-6">
                  {amountCurrency}
                </span>
              </div>
            </div>
          </div>
          <div className="relative z-30 flex h-6 w-6 flex-shrink-0 items-center justify-center bg-gray-100 md:h-8 md:w-8">
            <ArrowSwapHorizontalIcon className="absolute bottom-0 top-0 mb-auto mt-auto h-5 w-5 rotate-90 text-gray-500 md:h-7 md:w-7" />
          </div>
          <div className={clsx('relative flex h-full w-full flex-col justify-between rounded-[.625rem] border p-4 md:p-5', `before:absolute before:bg-gray-100 before:content-['']`, 'before:top-[-1.125rem]', 'before:left-0 before:right-0 before:ml-auto before:mr-auto before:h-8 before:w-[3.5rem] md:before:h-9 md:before:w-[4.25rem]', 'before:rounded-b-full before:rounded-tl-none before:rounded-tr-none')}>
            <p className="mb-3 text-body-8 text-gray-500">{cryptoInputLabel}</p>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center">
                <span className="text-center text-body-5 font-bold text-gray-600 md:text-h6">
                  {cryptoAmount}
                </span>
              </div>
              <CryptoSelector supportedCoins={coins} onChangeCrypto={handleCryptoChange} selectedCryptoSymbol={selectedCrypto} />
            </div>
          </div>
        </div>
      </div>;
});
CalculatorVertical.displayName = 'CalculatorVertical';
export const FormCalculatorVertical = ({
  name,
  restrictions,
  onChangeCrypto,
  ...rest
}: FormCalculatorVerticalProps) => {
  const lang = useLang();
  const {
    t
  } = useTranslation();
  const validator = useMemo(() => makeFinalAmountValidator(restrictions.validAmounts, lang, t), [lang, restrictions.validAmounts, t]);
  const {
    field
  } = useController({
    name,
    rules: {
      validate: validator
    }
  });
  return <CalculatorVertical name={name} amount={field.value} setAmount={value => {
    field.onChange(value);
  }} ref={field.ref} onBlur={field.onBlur} disabled={field.disabled} onChangeCrypto={onChangeCrypto} {...rest} data-sentry-element="CalculatorVertical" data-sentry-component="FormCalculatorVertical" data-sentry-source-file="index.tsx" />;
};
export default CalculatorVertical;