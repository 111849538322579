'use client';

import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { PrismicNextImage } from '@prismicio/next';
import { ChevronDownIcon } from '@/public/assets/svg';
import BtcIcon from '@/public/assets/svg/crypto/BTC.svg';
import CryptoSelectorModal from '../CryptoSelectorModal';
import { CryptoSelectorProps } from './types';
const CryptoSelector = ({
  supportedCoins = [],
  onChangeCrypto,
  className,
  selectedCryptoSymbol
}: CryptoSelectorProps) => {
  const hasChoices = supportedCoins.length > 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedCrypto = hasChoices ? supportedCoins.find(coin => coin.symbol === selectedCryptoSymbol?.toUpperCase()) : null;
  const handleCryptoChange = (crypto: string) => {
    onChangeCrypto(crypto);
  };
  if (!hasChoices) {
    return <div className={clsx('flex items-center justify-between rounded-full border border-gray-200 bg-white px-3 py-2', className)}>
        <div className="flex flex-row items-center justify-center">
          <BtcIcon className="h-6 w-6 md:h-8 md:w-8" />
          <span className="ml-2 text-body-7 font-semibold text-gray-600 md:text-body-6">
            {selectedCryptoSymbol?.toUpperCase()}
          </span>
        </div>
      </div>;
  }
  const CryptoSelectorButton = ({
    onClick
  }: {
    onClick: () => void;
  }) => <button type="button" onClick={onClick} className={clsx('flex w-full items-center justify-between rounded-full border border-gray-200 bg-white px-3 py-2', className)} data-sentry-component="CryptoSelectorButton" data-sentry-source-file="index.tsx">
      <div className="flex flex-row items-center justify-center">
        <PrismicNextImage field={selectedCrypto?.icon} className="h-6 w-6 md:h-8 md:w-8" data-sentry-element="PrismicNextImage" data-sentry-source-file="index.tsx" />
        <span className="ml-2 text-body-7 font-semibold text-gray-600 md:text-body-6">
          {selectedCrypto?.symbol?.toUpperCase() || ''}
        </span>
        <ChevronDownIcon className="ml-1 h-4 w-4" data-sentry-element="ChevronDownIcon" data-sentry-source-file="index.tsx" />
      </div>
    </button>;
  return <>
      <div className="hidden md:block">
        <Menu as="div" className={className} data-sentry-element="Menu" data-sentry-source-file="index.tsx">
          {({
          open,
          close
        }) => <>
              <MenuButton className={clsx('flex w-full items-center justify-between rounded-full border px-3 py-2', {
            'border-primary-600 bg-primary-100': open,
            'border-gray-200 bg-white': !open
          })} aria-label="flag button">
                <div className="flex flex-row items-center justify-center">
                  <PrismicNextImage field={selectedCrypto?.icon} className="h-6 w-6 md:h-8 md:w-8" />
                  <span className="ml-2 text-body-6 font-semibold text-gray-600">
                    {selectedCrypto?.symbol?.toUpperCase() || ''}
                  </span>
                  <ChevronDownIcon className="ml-1 block h-4 w-4" />
                </div>
              </MenuButton>
              <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0" enterTo="opacity-100" leave="transition ease-in duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
                <MenuItems modal={false} anchor={'bottom start'} className={clsx('z-50 mt-2 origin-top-right rounded-xl border border-primary-600 bg-white py-2 text-body-6 text-gray-600 focus:outline-none')}>
                  {supportedCoins.map(coin => {
                const symbol = coin.symbol?.toString() || '';
                return <button key={symbol} type="button" onClick={() => {
                  handleCryptoChange(symbol);
                  close();
                }} className={clsx('flex w-full items-center gap-3 px-3 py-1.5 text-left text-sm text-gray-500 hover:bg-primary-100 hover:text-gray-600', {
                  'bg-primary-50 font-medium text-gray-600': symbol === selectedCryptoSymbol
                })}>
                        <PrismicNextImage field={coin.icon} width={32} height={32} />
                        <span className="text-gray-700">
                          {coin.name} ({symbol.toUpperCase()})
                        </span>
                      </button>;
              })}
                </MenuItems>
              </Transition>
            </>}
        </Menu>
      </div>
      <div className="md:hidden">
        <CryptoSelectorButton onClick={() => setIsModalOpen(true)} data-sentry-element="CryptoSelectorButton" data-sentry-source-file="index.tsx" />
        <CryptoSelectorModal coins={supportedCoins} onSubmit={(symbol: string) => {
        handleCryptoChange(symbol);
        setIsModalOpen(false);
      }} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} selectedCoinSymbol={selectedCryptoSymbol || ''} data-sentry-element="CryptoSelectorModal" data-sentry-source-file="index.tsx" />
      </div>
    </>;
};
export default CryptoSelector;